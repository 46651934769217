import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WalletSearch = () => {
  const navigate = useNavigate();

  const [address, setAddress] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [wallets, setWallets] = useState([]);

  const searchAddress = (e) => {
    e.preventDefault();
    setNotFound(false);
    const addressData = wallets.find((wallet) => wallet.address === address);
    if (addressData) {
      navigate("/wallet/" + addressData.address);
    } else {
      setNotFound(true);
    }
  };

  useEffect(() => {
    fetch("https://reports.jumeirah-bot.xyz/api/pays.json")
      .then((response) => response.json())
      .then((myJson) => {
        setWallets(myJson.payWallets);
      });
  }, []);

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <h5>Search Wallet</h5>
              <form onSubmit={searchAddress}>
                <input
                  style={{ width: "50%", height: "2rem" }}
                  type="text"
                  id="address"
                  name="address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  disabled={wallets.length === 0}
                />
                <br />
                <Button className="cta" type="submit">
                  Search
                </Button>
              </form>

              {notFound && (
                <div>
                  <h1>Wallet not found</h1>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WalletSearch;
