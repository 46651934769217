import React from "react";
import { Container } from "react-bootstrap";

const IframeContainer = ({ src }) => {
  return (
    <Container>
      <iframe
        style={{ width: "100%", height: "86vh" }}
        src={src}
        title="Bot Investments Report"
      ></iframe>
    </Container>
  );
};

export default IframeContainer;
