import React, { useState, useEffect } from "react";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const WalletView = () => {
  const location = useLocation();

  const [address, setAddress] = useState();

  useEffect(() => {
    fetch("https://reports.jumeirah-bot.xyz/api/pays.json")
      .then((response) => response.json())
      .then((myJson) => {
        setAddress(
          myJson.payWallets.find(
            (wallet) => wallet.address === location.pathname.split("/")[2]
          )
        );
      });
  }, [location.pathname]);

  const getTotal = (array) => {
    let total = 0;
    for (let i = 0; i < array.length; i++) {
      total += array[i].amount || 0;
    }
    return total;
  };

  return address ? (
    <Container>
      <Row>
        <h1>My Wallet</h1>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <h5>Deposits</h5>

              <ListGroup as="ul">
                {[...address.deposits].reverse().map((deposit, index) => (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{deposit.amount} SOL</div>
                      {new Date(deposit.timestamp).toDateString()}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <h6 className="mt-1">Total: {getTotal(address.deposits)} SOL</h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <h5>Payout</h5>

              <ListGroup as="ul">
                {[...address.payouts].reverse().map((payout, index) => (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">
                        {payout.amount} SOL - {payout.percentage}%{" "}
                      </div>
                      {new Date(payout.timestamp).toDateString()}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <h6 className="mt-1">Total: {getTotal(address.payouts)} SOL</h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <h5>Bonuses</h5>

              <ListGroup as="ul">
                {[...address.bonuses].reverse().map((y, index) => (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{y.amount} SOL</div>
                      {new Date(y.timestamp).toDateString()}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <h6 className="mt-1">Total: {getTotal(address.bonuses)} SOL</h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <h5>Yield</h5>

              <ListGroup as="ul">
                {[...address.yields].reverse().map((y, index) => (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{y.amount} SOL</div>
                      {new Date(y.timestamp).toDateString()}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default WalletView;
