import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import WalletView from "./components/WalletView";
import WalletSearch from "./components/WalletSearch";
import HomePage from "./components/HomePage";
import { Container, Row } from "react-bootstrap";
import Header from "./components/Header";
import IframeContainer from "./components/IframeContainer";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/wallet",
      element: <WalletSearch />,
    },
    {
      path: "/wallet/:address",
      element: <WalletView />,
    },
    {
      path: "/botinvestments",
      element: (
        <IframeContainer src="https://reports.jumeirah-bot.xyz/reports/botinvestments" />
      ),
    },
    {
      path: "/bottransactions",
      element: (
        <IframeContainer src="https://reports.jumeirah-bot.xyz/reports/bottransactions" />
      ),
    },
  ]);

  return (
    <div className="App">
      <Header />

      <RouterProvider router={router} />
      <Container fluid className="footer">
        <Row>
          <h6>&copy; 2024 Jumeirah Bot. All rights reserved.</h6>
        </Row>
      </Container>
    </div>
  );
}

export default App;
