import React from "react";
import { Container, Navbar, Image, Offcanvas, Nav } from "react-bootstrap";

const Header = () => {
  return (
    <Navbar expand={false} className="app-header mb-3">
      <Container fluid>
        <Navbar.Brand href="/">
          <Image style={{ width: "6rem" }} src="/jumeirah-logo.png" /> Jumeirah
          Bot
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand`}
          aria-labelledby={`offcanvasNavbarLabel-expand`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              Jumeirah Bot
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/wallet">Wallet</Nav.Link>
              <Nav.Link href="/botinvestments">Investments</Nav.Link>
              <Nav.Link href="/bottransactions">Transactions</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
