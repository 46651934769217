import React from "react";
// import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const HomePage = () => {
  // const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <h1>Earn 5% Weekly Yield with Jumeirah Bot</h1>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Text>
                Welcome to Jumeirah Bot, your gateway to effortless earnings in
                the crypto world. Our innovative bot offers a guaranteed 5%
                yield every week to all participants. Join our community and
                start growing your investments with minimal effort and maximum
                returns.
              </Card.Text>
              <Card.Text>
                Jumeirah Bot trades memecoins on the Solana network, leveraging
                advanced algorithms and market insights to maximize your
                returns. With a user-friendly interface and robust security
                measures, our bot ensures a seamless and secure investment
                experience.
              </Card.Text>
              <Card.Text>
                Sign up today and experience the future of passive income with
                Jumeirah Bot. Watch your investments grow as our bot works
                tirelessly to bring you consistent weekly profits.
              </Card.Text>
              <Button className="cta">Get Started (Coming Soon)</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* _____________________ */}

      <Row>
        <h2>Features</h2>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-4 mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-coins"></i> High Yield
              </Card.Title>
              <Card.Text>
                Enjoy a guaranteed 5% yield every week, significantly higher
                than traditional investments.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-robot"></i> Advanced Trading
              </Card.Title>
              <Card.Text>
                Our bot trades memecoins on the Solana network, using
                sophisticated algorithms to optimize your returns.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-shield-alt"></i> Secure and Reliable
              </Card.Title>
              <Card.Text>
                We prioritize your security with advanced encryption and robust
                security protocols.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* _____________________ */}
      <Row>
        <h2>How It Works</h2>
      </Row>
      <Row xs={1} md={2} lg={3} className="g-4 mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-user-plus"></i> Sign Up
              </Card.Title>
              <Card.Text>
                Create an account in minutes and join the Jumeirah Bot
                community.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-wallet"></i> {"  "}Deposit Funds
              </Card.Title>
              <Card.Text>
                Deposit your crypto assets and let our bot start trading for
                you.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Title>
                <i class="fas fa-chart-line"></i> Earn Weekly
              </Card.Title>
              <Card.Text>
                Sit back and watch your investments grow with a 5% weekly yield.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* _____________________ */}

      <Row>
        <h2>Our Vision</h2>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card className="p-3">
            <Card.Body>
              <Card.Text>
                At Jumeirah Bot, we envision a future where everyone has access
                to reliable and profitable investment opportunities in the
                crypto world. We aim to democratize financial growth by
                leveraging cutting-edge technology and market expertise. Our
                mission is to provide a seamless, secure, and highly profitable
                investment experience for all our users. Join us on this journey
                and be part of the financial revolution.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
